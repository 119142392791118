.unlock-key{
    text-align: center;
    background: white;
    width: 500px;
    padding: 14px;
    border-radius: 14px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
}
.unlock-key p{
    text-transform: capitalize;
    text-align: left;
    text-indent: 45px;
    font-size: 18px;
}
.unlock-key button{
    font-family: monospace;
    width: 100%;
    height: 100px;
    background: white !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    padding: 14px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    border-radius: 10px;
    font-size: 23px;
}
.unlock-key button:hover {
    transform: scale(1.03);
}
.unlock-key button:last-child div:first-child{
    float: left;
}
.unlock-key button:last-child div:last-child{
    float: right;
}
.unlock-key button div{
    float: left !important;
}
.unlock-bar{
    background: linear-gradient(to right, red, rgb(255, 0, 212));
    border-radius: 6px;
    height: 6px;
    margin-bottom: 15px;
    transition: all 1s;
}
.icons8-link { display: inline-block; width: 50px; height: 50px; background: url('https://img.icons8.com/?size=512&id=92&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-lock { display: inline-block; width: 50px; height: 50px; background: url('https://img.icons8.com/?size=512&id=94&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-discord { display: inline-block; width: 50px; height: 50px; background: url('https://img.icons8.com/?size=512&id=25627&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-review { display: inline-block; width: 50px; height: 50px; background: url('https://img.icons8.com/?size=512&id=37927&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-github { display: inline-block; width: 50px; height: 50px; background: url('https://img.icons8.com/?size=512&id=106562&format=png') 50% 50% no-repeat; background-size: 100%; }

.unlock-key button {
    display: flex;
    align-items: center;
  }
  
  .icon-left {
    margin-right: 5px;
  }
  
  .icon-right {
    margin-left: 5px;
  }
  
  .text {
    flex-grow: 1;
    text-align: center;
    color: black;
    margin-left: 0;
    text-transform: capitalize;
  }
  .text:last-child{
    margin-left: -50px;
  }
.headertext{
  text-align: center;
  font-size: 30px;
}
.errordiv{
  background-color: rgba(255, 255, 255, 0);
  padding: 15px;
  border-radius: 10px;
  width: 600px;
  height: 500px;
  margin: auto;
  margin-top: 10px;
  text-align: center;
  font-size: larger;
  padding: 150px;
}