*{
  font-weight: 400;
}

.App {
 text-align: center;
 margin: auto;
 margin-top: 170px;

}
.Bold{
  font-weight: 600;

}
.verInput{
  width: 400px;
  height: 90px;
  border-radius: 10px;
  border: none;
  outline: none;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: monospace !important;
  transition: all 0.1s;
  font-size: 22px;
}
.verInput:hover{
  transform: scale(1.03);
}
.verInput:focus{
  border: none;
  outline: none;
  transform: scale(1);
}
.verBtn{
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: monospace !important;
  width: 407px;
  height: 85px;
  align-items: center;
  background-color: #000000;
  border-radius: 12px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-size: 1.2rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin-top: 10px;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  color: white;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  transition: all 0.2;
  font-size: 19px;
}
.verBtn:hover{
  transform: scale(1.04);
}
@keyframes ellipsis {
  0% {
    content: "...";
  }
  25% {
    content: "..";
  }
  50% {
    content: ".";
  }
  75% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

#loadingText::after {
  content: "...";
  animation: ellipsis 1s infinite;
}
.iconsBtns:hover{
  transform: scale(1.1);
}
.Options button{
  background: transparent;
  border: none;
  outline: none;
  font-size: xxx-large;
}
.Options button:hover{
transform: scale(1.09);
}
.Options{
  margin-top: 90px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 290px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;

  position: fixed;
  left: 10px;
  bottom: 10px;
  animation: move 1.3s ease-in-out forwards;
  z-index: 9999;
  
}
.Options img{
  position: relative;
  left: -10px;
}
@keyframes move {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
