.home{
padding: 30px;
margin: auto;
text-align: center;
}
.t hr{
width: 600px;
}
.contentdivider hr{
width: 70%;
}
.inlineDisplay{
display: inline;
padding: 15px;
}
.longCard img{
border-radius: 9px;
}
.longCard{
width: 80%;
margin: auto;
}

.longCard div{
display: contents;
}
.longCard a{
color: black;
text-decoration: none;
font-weight: 600;
}
.longCard a:hover{
text-decoration: underline dotted;
}
.Bold{
font-weight: 700;
}
.AllCaps{
text-transform: uppercase;
}
.containerWthis {
max-width: 600px;
margin: 50px auto;
padding: 20px;
background-color: #fff;
border-radius: 8px;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.inputtext[type="text"] {
width: calc(100% - 80px);
padding: 10px;
border-radius: 4px 0 0 4px;
border: 1px solid #ccc;
font-size: 16px;
box-sizing: border-box;
height: 60px;
outline: none;
border: none;
}

.button {
width: 80px;
padding: 10px;
border-radius: 0 4px 4px 0;
border: none;
background-color: #007bff;
color: #fff;
font-size: 16px;
cursor: pointer;
transition: background-color 0.3s ease;
box-sizing: border-box;
height: 60px;
font-weight: 900;
}

.button:hover {
background-color: #006ee4;
}
.result{
text-align: left;

}
.less{
    width: 600px;
    font-size: 35px;
    font-weight: 550;
}
.resultImage{
width: 100%;
object-fit: cover;
height: 160px;
margin-top: 50px;

}

.skeleton-loading {
display: inline-block;
background-color: #f0f0f0;
margin-top: 50px;
border-radius: 10px;
float: left;
border-radius: 4px;
animation: loading 1s ease-in-out infinite alternate;
}

@keyframes loading {
0% {
    opacity: 0.5;
}
100% {
    opacity: 1;
}
}
.displaythsi{
    background-color: #fff;
    height: 400px;
}
.buttonrel{
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    font-size: 22px;
    width: 100%;
    height: 100px;
    outline: none;
    border: none;
    border-radius: 10px;
    transition: all 0.09s;
}
.buttonrel:hover{
    transform: scale(1.025);
}
.cols{
    position: static;
    height: 50px;
}
.pricing{
    background: white;
    width: 350px;
    height: 500px;
    margin: auto;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    color: black;
  }
  .pricing h2{
    font-size: 30px;
  }
  .pricing:hover{
    transform: scale(1.01);
  }
.priceHolder{
display: flex;
margin: auto;
width: fit-content;
gap: 46px;
}
.script{
    display: flex;
    align-self: center;
    align-items: center;
    padding: 30px;
    margin: auto;
    gap: 20px;
    width: 60%;
    height: 150px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
}
.script a{
    text-decoration: none;
    font-size: larger;
    color: rgb(0, 0, 0);
    font-weight: bolder;

}
.script a:hover{
    text-decoration: none;
    transform: scale(1.04);
}
.coloredname{
    font-size: 3rem;
    font-weight: 1000;
    background: linear-gradient(45deg, #FF6B6B, #ff3d3d, #fd9a9a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.card4script{
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 600px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    margin: auto;
    text-align: left;
    margin-top: 10px;
    transition: all 0.1s;

}
.card4script:hover{
    transform: scale(1.009);
}
.card4script h2{
    font-size: 26px;
    text-align: left;
    font-weight: bold;
}
.learnmorecard{
    background-color: white;
    padding: 15px;
    border-radius: 10px;
    width: 600px;
    height: 500px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    margin: auto;
    margin-top: 10px;
}
.geomatery{
    width: 150px;
    height: 150px;
    border-radius: 100%;
    position: relative;
    z-index: -1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);;
}
.pointMakingh{
    font-size: 40px;
    font-weight: bold;
}
.learnmorecard *{
    transition: all 0.1s;
}
.learnmorecard section{
    margin-top: 25%;
}
.rating-card {
    background: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    width: 290px;
    margin-top: 35px;
    text-align: center;
    max-width: 350px;
    transition: transform 0.3s;
    min-height: 250px;
}

.rating-card:hover {
    transform: scale(1.02);
}

/* Title styling */
.rating-card h2 {
    font-size: 1.8em;
    margin-bottom: 15px;
    color: #333333;
    font-weight: bold;
}

/* Star container styling */
.stars {
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

/* Individual star styling */
.star {
    width: 35px;
    height: 35px;
    background: #ffd700;
    clip-path: polygon(
        50% 0%,
        61% 35%,
        98% 35%,
        68% 57%,
        79% 91%,
        50% 70%,
        21% 91%,
        32% 57%,
        2% 35%,
        39% 35%
    );
    margin: 0 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, background-color 0.2s;
}

.star:hover {
    transform: scale(1.2);
    background: #ff6347;
}

/* Paragraph styling */
.rating-card p {
    font-size: 1.1em;
    color: #666666;
    margin-top: -17px;
    line-height: 1.4;
    padding: 20px; 
}
.icons8-trust { display: inline-block; width: 100px; height: 100px; background: url('https://img.icons8.com/?size=512&id=hmjf7ms7Hb1h&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-stats { display: inline-block; width: 100px; height: 100px; background: url('https://img.icons8.com/?size=512&id=15&format=png') 50% 50% no-repeat; background-size: 100%; }
.icons8-about { display: inline-block; width: 140px; height: 140px; background: url('https://img.icons8.com/?size=512&id=80eweJGFK1F4&format=png') 50% 50% no-repeat; background-size: 100%; }
.bgicon1 {
    display: inline-block;
    width: 300px;
    height: 300px;
    background-size: 100%;
    position: absolute;
    z-index: -1 !important;
    margin-left: 200px;
    transform: rotate(45deg);
}