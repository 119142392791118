    .large-cards-container {
      display: grid;
      grid-template-rows: repeat(3, auto); /* Three rows */
      grid-template-columns: repeat(3, 1fr); /* Three columns */
      gap: 20px; /* Gap between grid items */
      margin: auto;
      width: 75%;
  }
  
  .large-card {
    max-width: 400px;
    height: 450px;
    padding: 20px;
    margin-right: 20px;
    border: none;
    border-radius: 8px;
    outline: none;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    transition: all 0.1s;
    text-decoration: none;
    color: black;
    overflow: hidden;
  }
  .large-card:hover{
    transform: scale(1.02);
  }
 .icon{
  width: 45px;
  height: 45px;
  object-fit: cover;
 }
 .learn-more-button {
  float: right;
  position: relative;
  display: inline;
  padding: 15px;
  top: -51px;
  color: black;
  border: none;
  font-weight: 700;
  outline: none;
  border-radius: 7px;
  background: #fff;
}
 .cookie-content{
  display: inline;
 }
 button.cookie-consent-button:hover {
  transform: scale(1.05);
}
.JsonBlock{
  background: white;
  border-radius: 17px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  padding: 50px;
  padding-top: 90px;
  padding-bottom: 90px;
 }
 .history-api-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.api-description {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}
.captext{
  text-transform: capitalize;
  width: 500px;
  text-align: center;
  margin: auto;
}
.statsMenu{
  text-align: center;
  font-size: 15px;
}